import React from 'react';
import {
  Thumbnail2d,
  ThumbnailTypes,
  ThumbnailGameThumbnailSize,
  ThumbnailFormat
} from 'roblox-thumbnails';
import { TGameData } from '../types/bedev1Types';

type TWideGameThumbnailProps = {
  gameData: TGameData;
};

const WideGameThumbnail = ({ gameData }: TWideGameThumbnailProps): JSX.Element => {
  if (
    gameData.primaryMediaAsset?.wideImageAssetId &&
    gameData.primaryMediaAsset.wideImageAssetId !== '0' &&
    !Number.isNaN(gameData.primaryMediaAsset.wideImageAssetId)
  ) {
    return (
      <Thumbnail2d
        type={ThumbnailTypes.assetThumbnail}
        size={ThumbnailGameThumbnailSize.width384}
        targetId={parseInt(gameData.primaryMediaAsset.wideImageAssetId, 10)}
        containerClass='brief-game-icon'
        format={ThumbnailFormat.jpeg}
        altName={gameData.name}
      />
    );
  }

  return (
    <Thumbnail2d
      type={ThumbnailTypes.gameThumbnail}
      size={ThumbnailGameThumbnailSize.width384}
      targetId={gameData.placeId}
      containerClass='brief-game-icon'
      format={ThumbnailFormat.jpeg}
      altName={gameData.name}
    />
  );
};

export default WideGameThumbnail;
