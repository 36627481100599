type TConfig = {
  common: string[];
  feature: string;
};

export const translationConfig: TConfig = {
  common: [],
  feature: 'Feature.GameDetails'
};

export default translationConfig;
