export const homePage = {
  maxTilesPerCarouselPage: 6,
  maxWideGameTilesPerCarouselPage: 4,
  minWideGameTilesPerCarouselPage: 2,
  gameTileWidth: 150,
  homeFeedMaxWidth: 970,
  wideGameTileTilesPerRowBreakpointWidth: 738,
  sortlessGridMaxTilesMetadataToFetch: 300,
  adSortHomePageId: 400000000,
  topicIdsWithAlwaysDividers: [500000000, 500000001],
  topicIdsWithoutSeeAll: [500000000, 500000001],
  friendsCarouselAngularBootstrapErrorEvent: 'HomePageFriendsCarouselBootstrapError'
};

export const gamesPage = {
  numGameCarouselLookAheadWindows: 3,
  adSortDiscoverId: 27
};

export const gameDetailsPage = {
  maxTilesPerCarouselPage: 6,
  voiceLabelIndex: 3,
  visitsTruncationDigitsAfterDecimalPoint: 1
};

export const common = {
  maxTilesInGameImpressionsEvent: 25,
  gameImpressionsIntersectionThreshold: 0.5,
  numberOfInGameAvatarIcons: 3,
  numberOfInGameNames: 1,
  maxFacepileFriendCountValue: 99,
  numberOfGameTilesPerLoad: 60,
  numberOfGamePassesPerLoad: 50,
  keyBoardEventCode: {
    enter: 'Enter'
  },
  RatingPercentageText: 'Label.RatingPercentage'
};

export const gameSearchPage = {
  // when 10% of pixels on sentinel tile are visible, load more data
  sentinelTileIntersectionThreshold: 0.1
};

export default {
  homePage,
  gamesPage,
  gameDetailsPage,
  common
};
