import React, { Ref, useState, useMemo, useEffect } from 'react';
import { Link } from 'react-style-guide';
import { TranslateFunction } from 'react-utilities';
import classNames from 'classnames';
import GameTilePlayButton from './GameTilePlayButton';
import WideGameThumbnail from './WideGameThumbnail';
import browserUtils from '../utils/browserUtils';
import {
  TGameData,
  TGetFriendsResponse,
  TLayoutComponentType,
  TLayoutMetadata
} from '../types/bedev1Types';
import {
  WideGameTileFacepileFooter,
  GameTileRatingFooter,
  TBuildEventProperties,
  GameTileIconWithTextFooter,
  GameTilePlayerCountPill,
  GameTileTextFooter
} from './GameTileUtils';
import { debounce } from '../utils/helperUtils';
import configConstants from '../constants/configConstants';
import { getInGameFriends, getFriendVisits } from '../utils/parsingUtils';
import { TComponentType, TPlayButtonStyle, TPlayerCountStyle } from '../types/bedev2Types';
import bedev1Services from '../services/bedev1Services';

export type TWideGameTileProps = {
  gameData: TGameData;
  id: number;
  buildEventProperties: TBuildEventProperties;
  friendData?: TGetFriendsResponse[];
  playerCountStyle?: TPlayerCountStyle;
  playButtonStyle?: TPlayButtonStyle;
  navigationRootPlaceId?: string;
  wideTileType: TComponentType.GridTile | TComponentType.EventTile;
  topicId?: string;
  translate: TranslateFunction;
};

const WideGameTile = React.forwardRef(
  (
    {
      gameData,
      id,
      buildEventProperties,
      friendData = [],
      playerCountStyle,
      playButtonStyle,
      navigationRootPlaceId,
      wideTileType,
      topicId,
      translate
    }: TWideGameTileProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const isFirstTile = id === 0;
    const isLastTile = id === configConstants.homePage.maxWideGameTilesPerCarouselPage - 1;
    const [isFocused, setIsFocused] = useState(false);

    const [referralPlaceId, setReferralPlaceId] = useState<number>(gameData.placeId);

    useEffect(() => {
      if (navigationRootPlaceId && !Number.isNaN(navigationRootPlaceId)) {
        setReferralPlaceId(parseInt(navigationRootPlaceId, 10));
      } else if (gameData.navigationUid) {
        // Fetch the place ID to navigate to for this universe ID
        bedev1Services
          .getGameDetails(gameData.navigationUid)
          .then(data => {
            if (data?.rootPlaceId) {
              setReferralPlaceId(data.rootPlaceId);
            }
          })
          .catch(() => {
            // non-blocking, as we will fallback to gameData.placeId
          });
      }
    }, [navigationRootPlaceId, gameData.navigationUid]);

    const clientReferralUrl = useMemo(() => {
      return browserUtils.buildGameDetailUrl(
        referralPlaceId,
        gameData.name,
        buildEventProperties(gameData, id)
      );
    }, [gameData, buildEventProperties, id, referralPlaceId]);

    const [setFocusDebounced, cancelSetFocusDebounced] = debounce(() => {
      setIsFocused(true);
    }, 100);

    const [setFocusLostDebounced, cancelSetFocusLostDebounced] = debounce(() => {
      setIsFocused(false);
    }, 100);

    const onFocus = () => {
      cancelSetFocusLostDebounced();
      setFocusDebounced();
    };

    const onFocusLost = () => {
      cancelSetFocusDebounced();
      setFocusLostDebounced();
    };

    const playButtonEventProperties = buildEventProperties(gameData, id) as Record<
      string,
      string | number | undefined
    >;

    const friendsInGame = useMemo(() => getInGameFriends(friendData, gameData.universeId), [
      friendData,
      gameData.universeId
    ]);

    const friendVisits = useMemo(() => getFriendVisits(friendData, gameData.friendVisits), [
      friendData,
      gameData.friendVisits
    ]);

    const getLayoutData = (): TLayoutMetadata | undefined => {
      if (gameData.layoutDataBySort && topicId && gameData.layoutDataBySort[topicId]) {
        return gameData.layoutDataBySort[topicId];
      }

      return gameData.defaultLayoutData;
    };

    const getBaseTileMetadata = (): JSX.Element => {
      const gameLayoutData = getLayoutData();
      if (gameLayoutData?.footer) {
        if (gameLayoutData.footer.type === TLayoutComponentType.TextLabel) {
          return <GameTileTextFooter footerData={gameLayoutData.footer} />;
        }
      }
      if (friendsInGame?.length > 0) {
        return <WideGameTileFacepileFooter friendsData={friendsInGame} isOnline />;
      }
      if (friendVisits?.length > 0) {
        return <WideGameTileFacepileFooter friendsData={friendVisits} isOnline={false} />;
      }
      if (gameData.friendVisitedString) {
        return (
          <GameTileIconWithTextFooter
            iconClassName='icon-pastname'
            text={gameData.friendVisitedString}
          />
        );
      }
      return (
        <GameTileRatingFooter
          totalUpVotes={gameData.totalUpVotes}
          totalDownVotes={gameData.totalDownVotes}
          translate={translate}
        />
      );
    };

    const getHoverTileMetadata = (): JSX.Element | null => {
      if (
        gameData.minimumAge &&
        gameData.ageRecommendationDisplayName &&
        wideTileType !== TComponentType.EventTile
      ) {
        return (
          <div className='game-card-info' data-testid='game-tile-hover-age-rating'>
            <span className='info-label'>{gameData.ageRecommendationDisplayName}</span>
          </div>
        );
      }
      return null;
    };

    const getGameTileMetadata = (): JSX.Element => {
      return (
        <div className='wide-game-tile-metadata'>
          <div className='base-metadata'>{getBaseTileMetadata()}</div>
          <div className='hover-metadata'>{getHoverTileMetadata()}</div>
        </div>
      );
    };

    return (
      <li
        className={classNames(
          'list-item',
          'hover-game-tile',
          { 'grid-tile': wideTileType === TComponentType.GridTile },
          { 'event-tile': wideTileType === TComponentType.EventTile },
          { 'first-tile': isFirstTile },
          { 'last-tile': isLastTile },
          { focused: isFocused }
        )}
        data-testid='wide-game-tile'
        onMouseOver={onFocus}
        onMouseLeave={onFocusLost}
        onFocus={onFocus}
        onBlur={onFocusLost}
        id={gameData.universeId.toString()}>
        {gameData.universeId && (
          <div className='featured-game-container game-card-container' ref={ref}>
            <Link url={clientReferralUrl} className='game-card-link'>
              <div className='featured-game-icon-container'>
                <WideGameThumbnail gameData={gameData} />

                <GameTilePlayerCountPill
                  playerCount={gameData.playerCount}
                  playerCountStyle={playerCountStyle}
                />
              </div>

              <div className='info-container'>
                <div className='game-card-name game-name-title' title={gameData.name}>
                  {gameData.name}
                </div>
                {getGameTileMetadata()}
              </div>
            </Link>

            {isFocused && (
              <div data-testid='game-tile-hover-game-tile-contents' className='game-card-contents'>
                {playButtonStyle !== TPlayButtonStyle.Disabled &&
                  wideTileType !== TComponentType.EventTile && (
                    <GameTilePlayButton
                      universeId={gameData.universeId.toString()}
                      placeId={gameData.placeId.toString()}
                      playButtonEventProperties={playButtonEventProperties}
                      buttonClassName='btn-growth-xs play-button'
                      purchaseIconClassName='icon-robux-white'
                      clientReferralUrl={clientReferralUrl}
                      shouldPurchaseNavigateToDetails
                    />
                  )}
              </div>
            )}
          </div>
        )}
      </li>
    );
  }
);

WideGameTile.displayName = 'WideGameTile';
export default WideGameTile;
