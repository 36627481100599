import React from 'react';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import RecommendedGamesCarousel from './RecommendedGamesCarousel';
import { recommendedGamesContainer } from '../common/constants/browserConstants';

ready(() => {
  if (recommendedGamesContainer()) {
    render(<RecommendedGamesCarousel />, recommendedGamesContainer());
  }
});
