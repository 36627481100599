import React, { useState, useEffect, useMemo, forwardRef, Ref } from 'react';
import { TranslateFunction } from 'react-utilities';
import { TGetFriendsResponse, TGetPlaceDetails } from '../types/bedev1Types';
import bedev1Services from '../services/bedev1Services';
import { getInGameFriends } from '../utils/parsingUtils';
import {
  TSharedGameTileProps,
  GameTileStats,
  GameTileBase,
  GameTileFriendsInGame,
  GameTileFriendActivityFooter,
  GameTileSponsoredFooter
} from './GameTileUtils';
import '../../../../css/common/_gameTiles.scss';
import '../../../../css/gameCarousel/_tooltip.scss';
import { PageContext } from '../types/pageContext';

export type TGameTileProps = TSharedGameTileProps & {
  friendData?: TGetFriendsResponse[];
  className?: string;
  page?: PageContext;
  shouldShowMetadata?: boolean;
  isVisible?: boolean;
  isSponsoredFooterAllowed?: boolean;
  translate: TranslateFunction;
};

export const GameTile = forwardRef<HTMLDivElement, TGameTileProps>(
  (
    {
      id,
      buildEventProperties,
      gameData,
      page = PageContext.HomePage,
      className = 'grid-item-container game-card-container',
      friendData = [],
      isVisible = true,
      shouldShowMetadata = true,
      isSponsoredFooterAllowed = false,
      translate
    }: TGameTileProps,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    const [gameDetails, setGameDetails] = useState<TGetPlaceDetails | undefined>();
    const friendsInGame = useMemo(() => getInGameFriends(friendData, gameData.universeId), [
      friendData,
      gameData.universeId
    ]);

    useEffect(() => {
      const fetchGameDetails = async () => {
        try {
          const response = await bedev1Services.getPlaceDetails(gameData.placeId.toString());
          setGameDetails(response);
        } catch (e) {
          console.error(e);
        }
      };
      if (gameDetails === undefined && friendsInGame.length > 0) {
        // eslint-disable-next-line no-void
        void fetchGameDetails();
      }
    }, [friendsInGame, gameDetails]);

    const getGameTileContent = (): JSX.Element => {
      if (!shouldShowMetadata) {
        return <React.Fragment />;
      }
      if (gameData?.isShowSponsoredLabel || (gameData?.isSponsored && isSponsoredFooterAllowed)) {
        return <GameTileSponsoredFooter translate={translate} />;
      }
      if (friendsInGame.length > 0 && gameDetails) {
        return <GameTileFriendsInGame friendData={friendsInGame} gameData={gameDetails} />;
      }
      if (gameData?.friendActivityTitle) {
        return <GameTileFriendActivityFooter footerText={gameData.friendActivityTitle} />;
      }
      return (
        <GameTileStats
          totalUpVotes={gameData.totalUpVotes}
          totalDownVotes={gameData.totalDownVotes}
          playerCount={gameData.playerCount}
        />
      );
    };

    return (
      <div ref={ref} className={className} data-testid='game-tile'>
        <GameTileBase
          id={id}
          isVisible={isVisible}
          buildEventProperties={buildEventProperties}
          gameData={gameData}
          page={page}>
          {getGameTileContent()}
        </GameTileBase>
      </div>
    );
  }
);
GameTile.displayName = 'GameTile';
export default GameTile;
